import Card from '@mui/material/Card'
import Image, { StaticImageData } from 'next/image'
import React from 'react'

export type ICard = {
  title: string
  description: string
  image: StaticImageData | string
  number?: StaticImageData | string
  alt?: string
  hideTitle?: boolean
}

type IProps = {
  card: ICard
  cardBgColor?: string
}

const StepCard = ({
  card: { title, description, image, number, alt, hideTitle },
  cardBgColor
}: IProps) => {
  return (
    <Card
      elevation={0}
      className={`relative col-span-1 w-full min-w-[300px] max-w-[587px] sm:min-w-[340px] md:min-w-[260px] ${cardBgColor ? cardBgColor : ''}`}
    >
      <Card
        elevation={0}
        className="relative flex w-full flex-col items-center justify-center rounded-[30px] bg-gradient-to-t from-grey-300 to-grey-400"
      >
        {number && (
          <Image
            id="stepNumber"
            src={number}
            alt={title}
            width={30}
            height={30}
            sizes="(max-width: 768px) 15vw,
                  10vw"
            loading="eager"
            className="absolute right-[15px] top-[15px] size-[30px]"
          />
        )}
        <Image
          id="stepImg"
          src={image}
          alt={alt ?? title}
          width={436}
          height={406}
          loading="eager"
        />
      </Card>
      {!hideTitle && (
        <div className="mt-[30px] w-full">
          <p className="text-center text-lg font-semibold text-blue-500">
            {title}
          </p>
          <p className="mx-auto mt-3 max-w-80 text-center text-[15px] text-black-500">
            {description}
          </p>
        </div>
      )}
    </Card>
  )
}

export default React.memo(StepCard)
